<template>
  <div id="contentwrap">
    <PageTitle />
    <TestimonialSection />
    <Badges />
    <Reviews />
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle"
import Reviews from "../components/Reviews.vue"
import TestimonialSection from "../components/Testimonials.vue"
import Badges from '../components/Badges.vue'
export default {
    name: "Testimonial",
    components: {
        PageTitle,
        Reviews,
        TestimonialSection,
        Badges
    },
    metaInfo: {
        title: 'Testimonials',
        titleTemplate: '%s | Meredith Schlosser',
        meta: [{
            name: 'description',
            content: "Meredith Schlosser"
        }]
    },
}
</script>
<style lang="scss" scoped>
#contentwrap{
  position: unset !important;
}
</style>
