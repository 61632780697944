<template>
    <section>
        <div v-if="reviews.length" class="reviews">
            <div class="column" v-for="i in rows" :key="i">
                <div 
                    class="review-container"
                    v-for="j in reviewsLength"
                    :key="i*j"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="1000"
                    v-bind:data-aos-delay="i*100">
                    <div class="stars">
                        <img src="/images/star.svg" alt="Five Star Reviews">
                        <img src="/images/star.svg" alt="Five Star Reviews">
                        <img src="/images/star.svg" alt="Five Star Reviews">
                        <img src="/images/star.svg" alt="Five Star Reviews">
                        <img src="/images/star.svg" alt="Five Star Reviews">
                    </div>
                    <hr>
                    <h3 class="excerpt">
                        {{ combinedReviews[(i-1) + (j-1)*rows].Excerpt }}
                    </h3>
                    <p v-html="combinedReviews[(i-1) + (j-1)*rows].Review" class="review">
                    </p>
                    <p class="initials">
                        – {{ combinedReviews[(i-1) + (j-1)*rows].Initials  }}
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:"Reviews",
    computed:{
        reviews(){
            return this.$store.getters.reviews;
        },
        reviewsInOrder(){
            return this.reviews.filter(review => review.DisplayOrder);
        },
        reviewsRandowm(){
            function shuffleArray(array) {
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1)); // Generate random index
                    // Swap elements
                    [array[i], array[j]] = [array[j], array[i]];
                }
                return array;
            }

            return shuffleArray(this.reviews.filter(review => !review.DisplayOrder));
        },
        combinedReviews(){
            return [...this.reviewsInOrder, ...this.reviewsRandowm]
        },
        rows(){
            if(window.innerWidth > 980){
                return Math.min(3, this.reviews.length);
            }
            if(window.innerWidth > 576){
                return Math.min(2,  this.reviews.length);
            }
            return 1;
        },
        reviewsLength(){
            return Math.floor(this.reviews.length / this.rows);
        }
    }
}
</script>

<style lang="scss" scoped>
h1{
    text-align: center;
    margin: 3.2rem auto;
    font-size: 2.55rem;
    font-weight: 500;
}

.header{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    img{
        width: 140px;
        margin:0 20px;
    }
}

.reviews{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.column{
    width: 33.33%;
}

.review-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #f6f6f6;
    padding: 30px;
    margin: 20px;

    hr{
        width: 100%;
        align-self: center;
        opacity: 0.5;
        margin-top: 20px;
        border: 0;
        border-top: 1px solid #C2C2C2;
    }
    img{
        align-self: center;
    }
    .excerpt{
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 400;
        text-align: center;
        align-self: center;
    }
    .initials{
        font-family: "EB Garamond", serif;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        margin-top: 10px;
    }
    .review{
        font-family: "EB Garamond", serif;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
    }
}

.stars{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    gap: 20px;
}

@media (max-width: $tablet) {
    .column{
        width: 50%;
    }
}
@media (max-width: $mobile) {
    .column{
        width: 100%;
    }
    .header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        img{
            width: 25%;
            margin:0 5px;
        }
        hr{
            width: 80%;
            align-self: center;
            opacity: 0.5;
            margin: 0 5px;
        }
    }
}
</style>